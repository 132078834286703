<template>
  <div id="app" >
    <!-- <div id="nav">
      <router-link to="/">Home1</router-link> |
      <router-link to="/about">About1</router-link>
    </div> -->
    <router-view/>
      <!-- <van-tabbar v-model="active" v-if="not.indexOf(routeName)<0" route>
  <van-tabbar-item icon="home-o" to="/">home</van-tabbar-item>
  <van-tabbar-item icon="gem" to="/update">vip</van-tabbar-item>
  <van-tabbar-item icon="column" to="/mytask">task</van-tabbar-item>
  <van-tabbar-item icon="setting-o" to="/my">my</van-tabbar-item>
</van-tabbar> -->


<!-- <van-tabbar route v-if="not.indexOf(routeName)<0">
  <van-tabbar-item icon="wap-home" to="/">Home</van-tabbar-item>
  <van-tabbar-item icon="todo-list" to="/ac_list">News</van-tabbar-item>
  <van-tabbar-item icon="logistics" to="/land">Product</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o" to="/fund">Fund</van-tabbar-item>
  <van-tabbar-item icon="manager" to="/my">Mine</van-tabbar-item>
</van-tabbar> -->
  </div>
</template>
<script>
import { Tabbar, TabbarItem } from 'vant';
import Vue from 'vue';
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
     data () {
    return {
      title: '',
      active: 0,
      name:'',
      not:'Login,login,Registeremail,registeremail,language,Language,line,Line',
      productList: [],
      howExchangeItem: null,
      popHowExchange: false
    }
  },
    globalData:{
			
			name:'guo'
		},
   computed: {
    routeName: function () {
      console.log(this.$route.name)
     
      return this.$route.name
    },
  
    },
      methods: {
    howExchange: function (item) {
  
      // 如何兑换
      this.howExchangeItem = item
      this.popHowExchange = true
    },
    click_tab:function(index){
      console.log(index)
      let _this = this;
      if(index==0){
      _this.$router.push({ name: "Home", query: { redId: 1111 } });
      }else if(index==1){
      _this.$router.push({ name: "ac_list", query: { redId: 1111 } });
      }else if(index==2){
        _this.$router.push({ name: "farm", query: { redId: 1111 } });
      }else if(index==3){
        _this.$router.push({ name: "fund", query: { redId: 1111 } });
      }else if(index==4){
        _this.$router.push({ name: "my", query: { redId: 1111 } });
      }
    },
     tab:function(d){
    let _this = this
    switch(d){
      case 0:
        _this.$router.push({'name': 'Home', query: {redId: 1111}})
        break;
       case 1:
         _this.$router.push({'name': 'mytask', query: {redId: 1111}})
        break;
         case 2:
                 _this.$router.push({'name': 'Wenzhang', query: {redId: 1111}})
        break;
         case 3:
           _this.$router.push({'name': 'Login', query: {redId: 1111}})
        break;  
          case 4:
           _this.$router.push({'name': 'my', query: {redId: 1111}})
        break; 
    }
  }  
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.jinyong{
    pointer-events:none;
    -webkit-filter: grayscale(100%);

    /* Chrome, Safari, Opera */

    filter: grayscale(100%);
}
</style>
<style>
  @import "assets/css/common.css";
</style>