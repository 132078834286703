import * as types from './mutation-type'
import * as constant from '../constant'
import * as local from '../libs/local'
import Vue from 'vue'

export default {
  [types.LOGIN] (state, obj) {
    state.jwt = obj.jwt
    state.loginType = obj.loginType
    local.saveInfo('jwt', obj.jwt)
    local.saveInfo('loginType', obj.loginType)
  },
  [types.LOGOUT] (state) {
    state.jwt = ''
    state.loginType = constant.NOT_LOGIN
    state.userInfo = {}
    local.deleteInfo('jwt')
    local.deleteInfo('loginType')
    local.deleteInfo('isByLogin')
  },
  [types.USERINFO] (state, obj) {
    for (let key in obj) {
      Vue.set(state.userInfo, key, obj[key])
    }
  },
  [types.ASIDEBTN] (state, n) {
    state.asideBtn = n
  },
  [types.ASIDEBTNSHOW] (state, b) {
    state.asideBtnShow = b
  },
  [types.NAVSHOW] (state, b) {
    state.navShow = b
  },
  [types.CONFIG] (state, obj) {
    for (let key in obj) {
      Vue.set(state.config, key, obj[key])
    }
  }
}
