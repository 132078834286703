import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import * as constant from '../constant'
import UAParser from 'ua-parser-js'

Vue.use(Vuex)

const state = {
  ua: new UAParser().getResult(), // user agent
  jwt: '', // token
  loginType: constant.NOT_LOGIN, // 登录类型
  asideBtn: 0.16, // 右下角按钮位置
  asideBtnShow: true, // 右下角按钮位置显示
  navShow: true, // 底部导航显示
  indexAgentPop: false, // 是否点击了首页升级弹窗
  agentSuccess: false, // 升级银行家成功弹窗
  agentSuccessIndex: 0, // 升级银行家成功索引0（正式），1（高级），2（首席）
  autoInfo: null,
  userInfo: {}, // 用户信息
  config: {} // 系统信息
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
