import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import store from '../store'
// import * as loginUtil from '@/utils/loginUtil'
// import * as constant from '@/constant'
import * as local from '@/libs/local'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/buy',
        name: 'buy',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/buy.vue')
    },
    {
        path: '/income',
        name: 'income',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/income.vue')
    },
    {
        path: '/product_income',
        name: 'product_income',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/product_income.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/order.vue')
    },
    {
        path: '/jqr',
        name: 'jqr',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/jqr.vue')
    },
    {
        path: '/my_fund',
        name: 'my_fund',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/my_fund.vue')
    },
    {
        path: '/muchang',
        name: 'muchang',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/muchang.vue')
    },
    {
        path: '/farm',
        name: 'farm',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/muchang.vue')
    },
    {
        path: '/choumanghe',
        name: 'choumanghe',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/choumanghe.vue')
    },
    {
        path: '/manghe',
        name: 'manghe',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/manghe.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/detail.vue')
    },
    {
        path: '/jiaoyi',
        name: 'jiaoyi',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/jiaoyi.vue')
    },
    {
        path: '/trading',
        name: 'trading',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/jiaoyi.vue')
    },
    {
        path: '/xiaofei',
        name: 'xiaofei',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/xiaofei.vue')
    },
    {
        path: '/wenzhang',
        name: 'Wenzhang',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Wenzhang.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/registeremail',
        name: 'registeremail',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Registeremail.vue')
    },
    {
        path: '/line',
        name: 'line',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Line.vue')
    },
    {
        path: '/language',
        name: 'language',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/language.vue')
    },
    {
        path: '/mytask',
        name: 'mytask',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Mytask.vue')
    },
    {
        path: '/article',
        name: 'article',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/article.vue')
    },
    {
        path: '/my',
        name: 'my',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/My.vue')
    },
    {
        path: '/task',
        name: 'task',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/task.vue')
    },
    //seed_record
    {
        path: '/my_team',
        name: 'my_team',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/my_team.vue')
    },
    {
        path: '/my_seeds',
        name: 'my_seeds',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/my_seeds.vue')
    },
    {
        path: '/yuebao',
        name: 'yuebao',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/yuebao.vue')
    },
    {
        path: '/fund',
        name: 'fund',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/yuebao.vue')
    },
    {
        path: '/tasklist',
        name: 'tasklist',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/tasklist.vue')
    },
    {
        path: '/update',
        props: true,
        name: 'update',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/update.vue')
    },
    {
        path: '/info',
        name: 'info',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/info.vue')
    },
    {
        path: '/promote',
        name: 'promote',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/promote.vue')
    },
    {
        path: '/bankinfo',
        name: 'bankinfo',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/bankinfo.vue')
    },
    {
        path: '/fundRecord',
        name: 'fundRecord',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/fundRecord.vue')
    },
    {
        path: '/chongzhi',
        props: true,
        name: 'chongzhi',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/chongzhi.vue')
    },
    {
        path: '/top-up',
        props: true,
        name: 'top-up',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/chongzhi.vue')
    },
    {
        path: '/usdt',
        props: true,
        name: 'usdt',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/chongzhi1.vue')
    },
    {
        path: '/history',
        name: 'history',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/cz_history.vue')
    },
    {
        path: '/cz_history',
        name: 'cz_history',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/cz_history.vue')
    },
    {
        path: '/team',
        name: 'team',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/team.vue')
    },
    {
        path: '/ac_list',
        name: 'ac_list',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ac_list.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting  registeremail
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
    //watering_record
    ,
    {
        path: '/watering_record',
        name: 'watering_record',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/watering_record.vue')
    },
    //watering
    {
        path: '/watering',
        name: 'watering',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/watering.vue')
    },

    //withdrawal
    {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/withdrawal.vue')
    },
    //props
    {
        path: '/props',
        name: 'props',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/props.vue')
    },
    //land
    {
        path: '/land',
        name: 'land',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/land.vue')
    },
    //seeds
    {
        path: '/seeds',
        name: 'seeds',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/seeds.vue')
    },
    {
        path: '/my_props',
        name: 'my_props',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/my_props.vue')
    },
    //seed_record
    {
        path: '/seed_record',
        name: 'seed_record',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/seed_record.vue')
    },
    {
        path: '/usdt_record',
        name: 'usdt_record',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/usdt_record.vue')
    },
    {
        path: '/integral_record',
        name: 'integral_record',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/integral_record.vue')
    },
    {
        path: '/ac_detail',
        name: 'ac_detail',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ac_detail.vue')
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    console.log(from)
    console.log(to)
    let notLoginArr = ['Home', 'index', 'Login', 'login', 'registeremail', 'language', 'Language', 'line']
        // let needBindMobileArr = ['mine', 'newmine', 'order', 'write', 'cash']
    if (notLoginArr.indexOf(to.name) !== -1) {
        console.log(131)
        next()
        return
    }
    let jwt = local.readInfo('jwt')
    if (jwt === null || jwt === undefined || jwt === '') {
        console.log(1)
        if (to.name == 'Login') {
            next()
            return
        } else {
            next({
                path: '/login',
                query: to.query
            })
        }
    } else {
        console.log(2)
        next()
    }

})
export default router