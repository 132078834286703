import * as apis from '@/api/apis'
import * as config from '@/api/config'
import axios from 'axios'
import router from '@/router'
import { Toast } from 'vant';
import * as local from "@/libs/local";
// import * as local from '@/libs/local'

axios.defaults.baseURL = config.API_ROOT

const post = axios.post
const get = axios.get
    //const put = axios.put
const del = axios.delete

export function setAuthorization(auth) {
    if (auth && auth !== '') {
        axios.defaults.headers.post['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.get['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.put['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.delete['Authorization'] = 'Bearer ' + auth
    } else {
        axios.defaults.headers.post['Authorization'] = null
        delete axios.defaults.headers.post['Authorization']
        axios.defaults.headers.get['Authorization'] = null
        delete axios.defaults.headers.get['Authorization']
        axios.defaults.headers.put['Authorization'] = null
        delete axios.defaults.headers.put['Authorization']
        axios.defaults.headers.delete['Authorization'] = null
        delete axios.defaults.headers.delete['Authorization']
    }
}

export const WECHAT_OAUTH_URL = config.API_ROOT + apis.WECHAT_REDIRECT

let cancel
let promiseArr = {}
axios.interceptors.request.use(config => {
    // 发起请求时，取消掉当前正在进行的相同请求
    if (promiseArr[config.url]) {
        promiseArr[config.url]('操作取消')
        promiseArr[config.url] = cancel
    } else {
        promiseArr[config.url] = cancel
    }
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(
    response => {
        let res = response.data
            // 凭证过期
        console.log(res)
        if (res.code === -1) {
            local.deleteInfo('jwt')
            setAuthorization('')
            router.replace({ name: 'Login' })
            return response
        }
        return response
    },
    error => {
        console.log(error)
        Toast('Abnormal network request')
        return Promise.reject(error)
    }
)

// 请求拦截器  
axios.interceptors.request.use(  
    config => {  
      // 在发送请求之前做些什么  
      // 例如，你可以在这里补加参数  
     
        // 如果token存在，则设置到请求头中  
      // 如果需要添加其他参数到URL或作为POST数据的一部分，你可以这样做：  
      // 假设我们要添加一个名为'extraParam'的参数  
      if (config.method === 'get') {  
        // 对于GET请求，将参数添加到URL的查询字符串中  
        config.params = {  
          ...config.params,  
          lang: local.readInfo('car_lang') 
        };  
      } else if (config.method === 'post') {  
        // 对于POST请求，如果数据是JSON格式的，可以这样做  
        if (config.data) {  
          config.data = {  
            ...config.data,  
            lang: local.readInfo('car_lang')
          };  
        }  
      }  
      // 请确保返回config  
      return config;  
      
    },  
    error => {  
      // 对请求错误做些什么  
      return Promise.reject(error);  
    }  
  );  
    
export function sms(reqData, cb) {
    post(apis.Sms, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function biao(reqData, cb) {
    post(apis.Biao, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 手机号注册
export function register(reqData, cb) {
    post(apis.REGISTER, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 手机号登录
export function login(reqData, cb) {
    post(apis.LOGIN, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 验证支付密码
export function zhifu(reqData, cb) {
    post(apis.PWD, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function zhifu1(reqData, cb) {
    post(apis.PWD1, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 验证登录密码
export function l_pwd(reqData, cb) {
    post(apis.LPWD, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// zf密码
export function xz_pwd(reqData, cb) {
    post(apis.XZPWD, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 登录密码
export function xl_pwd(reqData, cb) {
    post(apis.XLPWD, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}


// 充值汇率
export function get_huilv(reqData, cb) {
    post(apis.Hui, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 提现汇率
export function get_huilv1(reqData, cb) {
    post(apis.Hui1, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function index_data(reqData, cb) {
    post(apis.INDEXDATA, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function help(reqData, cb) {
    post(apis.HELP, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 修改造型
export function head(reqData, cb) {
    post(apis.HEAD, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function get_task(reqData, cb) {
    post(apis.GETTASK, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function buy(reqData, cb) {
    post(apis.BUY, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 图片上传
export function uploadPic(reqData, cb, config) {
    post(apis.UPLOADPIC, reqData, config).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function buy_jqr(reqData, cb) {
    post(apis.BUY_JQR, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function jqr(reqData, cb) {
    post(apis.JQR, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function jiaoyi(reqData, cb) {
    post(apis.Jiaoyi, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function jfdh(reqData, cb) {
    post(apis.Jfdh, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function mylingyang(reqData, cb) {
    post(apis.Mylingyang, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function cowdetail(reqData, cb) {
    post(apis.Cowdetail, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function ac_detail(reqData, cb) {
    post(apis.Ac, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function zhihui11(reqData, cb) {
    post(apis.Zhihui, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function manghe_data(reqData, cb) {
    post(apis.Manghe_data, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function chou(reqData, cb) {
    post(apis.Chou, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function mh_list(reqData, cb) {
    post(apis.Mhlist, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function buydata111(reqData, cb) {
    post(apis.Buydata, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function channai(reqData, cb) {
    post(apis.Channai, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function shounai(reqData, cb) {
    post(apis.Shounai, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function maimai(reqData, cb) {
    post(apis.Maimai, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function in_task(reqData, cb) {
    post(apis.ADDTASK, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 获取等级列表
export function getinfo(reqData, cb) {
    post(apis.INFO, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 获取等级任务列表
export function get_task_list(reqData, cb) {
    post(apis.TASKLIST, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 获取等级列表
export function get_vip(reqData, cb) {
    post(apis.TaskINFO, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 支付下单
export function get_pay3(reqData, cb) {
    post(apis.PAY3, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 支付下单
export function get_pay1(reqData, cb) {
    post(apis.PAY1, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 支付下单
export function get_pay(reqData, cb) {
    post(apis.PAY2, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 我的任务
export function get_my_task(reqData, cb) {
    post(apis.MYTASK, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的明细
export function get_my_mingxi(reqData, cb) {
    post(apis.MYZIJIN, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 申请提现
export function tixian(reqData, cb) {
    post(apis.TIXIAN, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的团队
export function team(reqData, cb) {
    post(apis.TEAM, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的团队1
export function team1(reqData, cb) {
    post(apis.TEAM1, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 种子仓库
export function seed(reqData, cb) {
    post(apis.SEED, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的团队初始
export function team_init(reqData, cb) {
    post(apis.TEAMINIT, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 获取客服
export function kf(reqData, cb) {
    post(apis.KF, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 个人中心
export function my(reqData, cb) {
    post(apis.MY, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 土地列表
export function tudi(reqData, cb) {
    post(apis.Tudi, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 购买土地
export function buytudi(reqData, cb) {
    post(apis.BuyTudi, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的种子
export function myzz(reqData, cb) {
    post(apis.MyZz, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 种子列表
export function zzlist(reqData, cb) {
    post(apis.Zzlists, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 购买种子
export function buyzz(reqData, cb) {
    post(apis.BuyZz, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 我的道具
export function mydj(reqData, cb) {
    post(apis.MyDj, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 道具列表
export function djlist(reqData, cb) {
    post(apis.Djlists, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 购买道具
export function buydj(reqData, cb) {
    post(apis.BuyDj, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 积分记录
export function myjfjl(reqData, cb) {
    post(apis.Jfjl, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 种植记录
export function zzdetail(reqData, cb) {
    post(apis.zzdetail, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 浇水
export function jiao(reqData, cb) {
    post(apis.jiao, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 浇水记录
export function jiao_list(reqData, cb) {
    post(apis.jiao_list, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 积分记录
export function myjbjl(reqData, cb) {
    post(apis.Jbjl, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
// 获取完整市级列表
export function getCompleteCityList(reqData, cb) {
    get(config.API_ROOT + apis.FULLCITYLIST, { params: reqData }).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 获取POS订单列表
export function getPosOrderList(reqData, cb) {
    get(config.API_ROOT + apis.POSORDERLIST, { params: reqData }).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

// 删除POS机订单
export function deletePosOrder(reqData, cb) {
    del(config.API_ROOT + apis.POSORDERDELETE + urlify(reqData), reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}


function urlify(data) {
    if (!data) {
        return ''
    }
    var query = '?'
    for (var key in data) {
        if (data[key]) {
            query += (key + '=' + data[key]) + '&'
        }
    }
    if (query.length > 0) {
        query = query.slice(0, query.length - 1)
    }
    return query
}

export function cz_data(reqData, cb) {
    post(apis.Cz_data, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function usdt_pay(reqData, cb) {
    post(apis.usdt, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

//基金产品
export function fund(reqData, cb) {
    post(apis.fund, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
//buy基金产品
export function buy_fund(reqData, cb) {
    post(apis.buy_fund, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
//我的基金产品
export function myfund(reqData, cb) {
    post(apis.myfund, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
//万能请求
export function all(url, reqData, cb) {
    post(url, reqData).then(res => {
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}