import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible';


// import axios from 'axios';
// Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://127.0.0.1:5000/v1'
Vue.use(Vant)
Vue.config.productionTip = false
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')