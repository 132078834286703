import * as local from "@/libs/local";

export function useLocalState(user) {

    let setting1 = local.readInfo('setting1')
    let lingyang = local.readInfo('lingyang')
    let shougou_data = local.readInfo('shougou_data')
    let my_data = local.readInfo('my_data')
    let manghe_data = local.readInfo('manghe_data')
    let buy_data = local.readInfo('buy_data')
    if ("user" in setting1) {
        setting1.user = user;
        local.saveInfo("setting1", setting1)
    }
    if ("user" in lingyang) {
        lingyang.user = user;
        local.saveInfo("lingyang", lingyang)
    }
    if ("user" in shougou_data) {
        shougou_data.user = user;
        local.saveInfo("shougou_data", shougou_data)
    }
    if ("user" in my_data) {
        my_data.user = user;
        local.saveInfo("my_data", my_data)
    }
    if ("user" in manghe_data) {
        manghe_data.user = user;
        local.saveInfo("manghe_data", manghe_data)
    }
    if ("user" in buy_data) {
        buy_data.user = user;
        local.saveInfo("buy_data", buy_data)
    }
}