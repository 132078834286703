// 手机号注册
export const REGISTER = '/qintai_public/api.user1/reg'

// 手机号登录
export const LOGIN = '/qintai_public/api.user1/index'

// 手机号重置密码
export const RESETPASSWORD = '/h5/account/resetPassword'

// 获取当前的等级信息
export const INFO = '/qintai_public/api.user1/vip_list'
    //获取任务领取列表
export const TASKLIST = '/qintai_public/api.user1/task_list'
    // 获取当前的等级升级信息

export const TaskINFO = '/qintai_public/api.user1/vip'
    //payment下单
export const PAY3 = '/qintai_public/api.user1/get_pay2'
    //wepay下单
export const PAY1 = '/qintai_public/api.user1/get_pay1'

//wepay下单
export const PAY2 = '/qintai_public/api.user1/get_pay'

//我的任务
export const MYTASK = '/qintai_public/api.user1/my_task_list'

//我的资金明细 0,1收入2充值3提现
export const MYZIJIN = '/qintai_public/api.user1/mingxi_list'

//申请提现
export const TIXIAN = '/qintai_public/api.user1/tixian_apply'

//个人中心
export const MY = '/qintai_public/api.user1/members_data'

//获取客服列表
export const KF = '/qintai_public/api.user1/get_kefu'

//我的团队
export const TEAM = '/qintai_public/api.user1/team_list'
    //我的团队liebiao
export const TEAM1 = '/qintai_public/api.user1/team_list1'

//我的种子仓库
export const SEED = '/qintai_public/api.user1/my_seed'
    //我的团队初始
export const TEAMINIT = '/qintai_public/api.user1/team_init'

//支付密码验证
export const PWD = '/qintai_public/api.user1/bank_info'
export const PWD1 = '/qintai_public/api.user1/bank_info1'
    //登录密码验证
export const LPWD = '/qintai_public/api.user1/auth1'
    // 图片上传
export const UPLOADPIC = '/qintai_public/api.user1/upload'
    //zf密码
export const XZPWD = '/qintai_public/api.user1/update_zfpass'

//登录密码
export const XLPWD = '/qintai_public/api.user1/update_pass'


export const Cz_data = '/qintai_public/api.user1/cz_data'

export const usdt = '/qintai_public/api.user1/usdt'
    //头像
export const HEAD = '/qintai_public/api.user1/update_head'

export const INDEXDATA = '/qintai_public/api.user1/index_data'

export const HELP = '/qintai_public/api.user1/help_list'

export const GETTASK = '/qintai_public/api.user1/get_task'

export const ADDTASK = '/qintai_public/api.user1/in_task'

//购买奶牛
export const BUY = '/qintai_public/api.user1/buy'

//收购商页面数据
export const Jiaoyi = '/qintai_public/api.user1/jiaoyi_data'
    //牛奶交易发起
export const Maimai = '/qintai_public/api.user1/maimai'
    //我的领养
export const Mylingyang = '/qintai_public/api.user1/lingyang'
    //一键产奶
export const Channai = '/qintai_public/api.user1/channai'
    //一键收奶
export const Shounai = '/qintai_public/api.user1/shounai'
    //牛的详情
export const Cowdetail = '/qintai_public/api.user1/cow_detail'

//盲盒初始化数据
export const Manghe_data = '/qintai_public/api.user1/manghe_data'

//配抽盲盒
export const Chou = '/qintai_public/api.user1/chou'

//盲盒抽奖记录
export const Mhlist = '/qintai_public/api.user1/mh_list'

//分娩记录
export const Fmjl = '/qintai_public/api.user1/fmjl'

//牛的列表数据
export const Buydata = '/qintai_public/api.user1/buy_data'
    //获取可升级奶牛的列表
export const Ksjnn = '/qintai_public/api.user1/ksjnn'
    //升级奶牛
export const sjnn = '/qintai_public/api.user1/sjnn'
    //获取用户选择的可配种奶牛列表
export const Kpznn = '/qintai_public/api.user1/kpznn'
    //开始配种
export const Kspz = '/qintai_public/api.user1/kspz'
    //指挥牛
export const Zhihui = '/qintai_public/api.user1/zhihui'
    //JIQREN
export const JQR = '/qintai_public/api.user1/jqr_list'

export const BUY_JQR = '/qintai_public/api.user1/buy_jqr'

//土地列表
export const Tudi = '/qintai_public/api.Tudi/list'

//购买土地
export const BuyTudi = '/qintai_public/api.Tudi/buy'

//我的种子
export const MyZz = '/qintai_public/api.Tudi/my_zzlist'

//种子列表
export const Zzlists = '/qintai_public/api.Tudi/zzlist'

//购买种子
export const BuyZz = '/qintai_public/api.Tudi/buy_zz'

//我的道具
export const MyDj = '/qintai_public/api.Tudi/my_djlist'

//道具列表
export const Djlists = '/qintai_public/api.Tudi/djlist'

//购买道具
export const BuyDj = '/qintai_public/api.Tudi/buy_dj'

//积分兑换USDT
export const Jfdh = '/qintai_public/api.Tudi/jfdh'

//文章详情
export const Ac = '/qintai_public/api.Tudi/ac_detail'

//积分记录
export const Jfjl = '/qintai_public/api.Tudi/jfjl'

//好友种植记录
export const zzdetail = '/qintai_public/api.user1/zz_detail'

//浇水
export const jiao = '/qintai_public/api.Tudi/jiao'

//浇水记录
export const jiao_list = '/qintai_public/api.Tudi/jiao_list'

//usdt记录
export const Jbjl = '/qintai_public/api.Tudi/jbjl'

//获取标识
export const Biao = '/qintai_public/api.user1/biao'
    //获取验证码
export const Sms = '/qintai_public/api.user1/send_sms'

//获取充值汇率
export const Hui = '/qintai_public/api.user1/huilv'

//获取提现汇率
export const Hui1 = '/qintai_public/api.user1/huilv1'

//基金列表
export const fund = '/qintai_public/api.user1/fund'

//goumai
export const buy_fund = '/qintai_public/api.user1/buy_fund'

//我的基金
export const myfund = '/qintai_public/api.user1/myfund'