<template>
  <div class="home222">
    <van-nav-bar     @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
 {{ select_lang.name }}
  </template>
  <template #title>

    <img src="../assets/img/h_logo.png" style="height: 40px;margin-top: 5px;"/>
  </template>
  <template #left>
    <img src="../assets/images/customer.png" style="height: 30px;margin-top: 5px;"/>
  </template>
      </van-nav-bar>
    <div class="content">


   
     
     <!--轮播-->
     <div class="lunbo">
           <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
     <van-swipe-item v-for="(item,index) in banner" :key="index">
      <img :src="item.banner" style />
     </van-swipe-item>
     <!-- <van-swipe-item>
       <img src="../assets/img/banner9.png" style />
     </van-swipe-item> -->
   </van-swipe>
     </div>

     <div style="height:20px"></div>




      <!--顶部内容-->
        <div class="header public_flex">
          <div class="header1 public_flex">
            <div class="touxiang" @click="tomy">
                <img :src="user.head" style />
            </div>
            <div class="name one_hide">{{user.user_name}}</div>
          </div>
         <!--<div  class="header2 public_flex"  @click="tomy">
            <div class="head one_hide"  @click="tochongzhi" style="width:100%">
            UID:{{user.id}}
            </div>      
          </div>--> 

          <div  class="header2 public_flex" @click="to('usdt_record')">
            <div class="usdt one_hide"  @click="tochongzhi" style="width:100%">
             {{user.jinbi}}
            </div>      
          </div>
        </div>
       <!-- <div style="height:20px"></div>
        <div class="header public_flex">
          <div  class="header2 public_flex"  @click="to('my_fund')">
            <div class="points one_hide"  @click="tochongzhi" style="width:100%">
              {{user.jijinye}}
            </div>      
          </div>
     
        </div>
        -->
<!-- 
   
      <div style="height:20px"></div>
        <div class="gonggao">
          <van-notice-bar
  left-icon="volume-o"
  :text="lang==0?setting.gundong:this.lang==1?setting.gundong1:setting.gundong2"
/>
        </div> -->
  
 <!--宫格导航-->
 <div class="gongge">
 <van-grid :border="false" :column-num="4">
  <van-grid-item @click="to_share" text="ces">
    <div style="background-color: #07c160;border-radius: 10px;margin: 5px;"> 
      <img  src="../assets/img/nm (1)1.png" style="width: 70%;display: block;margin: 0 auto;"/>
      <p style="color: #000;font-size: 14px;text-align: center;font-weight: 600;line-height: 14px;">{{ langs.t2 }}</p>
    </div>
   
  </van-grid-item>
  <van-grid-item  @click="tochongzhi"  text="ces">
    <div style="background-color: #07c160;border-radius: 10px;margin: 5px;"> 
      <img  src="../assets/img/nm (5)1.png" style="width: 70%;display: block;margin: 0 auto;"/>
      <p style="color: #000;font-size: 14px;text-align: center;font-weight: 600;line-height: 14px;">{{ langs.t3 }}</p>
    </div>
  </van-grid-item>
  <van-grid-item @click="totixian" text="ces">
    <div style="background-color: #07c160;border-radius: 10px;margin: 5px;"> 
      <img  src="../assets/img/nm (2)1.png" style="width: 70%;display: block;margin: 0 auto;"/>
      <p style="color: #000;font-size: 14px;text-align: center;font-weight: 600;line-height: 14px;">{{ langs.t4 }}</p>
    </div>
  </van-grid-item>
  <van-grid-item @click="to_xz" text="ces">
    <div style="background-color: #07c160;border-radius: 10px;margin: 5px;"> 
      <img  src="../assets/img/nm (4)1.png" style="width: 70%;display: block;margin: 0 auto;"/>
      <p style="color: #000;font-size: 14px;text-align: center;font-weight: 600;line-height: 14px;">{{ langs.t5 }}</p>
    </div>
  </van-grid-item>
  <!-- <van-grid-item @click="tochongzhi" text="ces">

    <img  src="../assets/img/nm (2).png"/>
  </van-grid-item>
  <van-grid-item @click="totixian" text="ces">

    <div class="biaoti">{{ text3[lang] }}</div>
  </van-grid-item>
  <van-grid-item @click="daoju" text="ces">

    <div class="biaoti">{{ text4[lang] }}</div>
  </van-grid-item>

  <van-grid-item @click="zz" text="ces">

    <div class="biaoti">{{ text6[lang] }}</div>
  </van-grid-item>
  <van-grid-item @click="tudi" text="ces">

    <div class="biaoti">{{ text7[lang] }}</div>
  </van-grid-item>
  <van-grid-item @click="tomy" text="ces">

    <div class="biaoti">{{ text11[lang] }}</div>
  </van-grid-item>
  <van-grid-item @click="muchang" text="ces">
    <div class="biaoti">{{ text12[lang] }}</div>
  </van-grid-item> -->
</van-grid>
 </div>



 <div class="new_title">{{ langs.t6 }}</div>

 <div class="new_news">
  <div  @click="showac(item.id,1)"  v-for="(item,index) in news" :key="index">
    <van-cell :title="lang==0?item.name:lang==1?item.name1:item.name2" :label="item.jia_date?item.jia_date:'Three years ago'" is-link  />
  </div>
   <van-empty :description="langs.tp15" v-if="news.length<=0"/>
  </div>
  <div style="height:20px"></div>
  <div class="gengduo"  @click="toxw">{{ langs.t7 }}>>></div>


  <!-- <div class="jion" @click="tudi">
    <img  src="../assets/img/jion.jpg"/>
  </div> -->
  <div style="height:20px"></div>
  <div style="height:20px"></div>

  <div class="new_title">{{ langs.t8 }}</div>

   <div class="new_news">
  <div  @click="showac(item.id,2)"  v-for="(item,index) in help" :key="index">
    <van-cell :title="lang==0?item.name:lang==1?item.name1:item.name2" :label="item.jia_date?item.jia_date:'Three years ago'" is-link  />
  </div>
   <van-empty :description="langs.tp15" v-if="help.length<=0"/>
  </div>
  <div style="height:20px"></div>
  <div class="gengduo"  @click="toxw">{{ langs.t7 }}>>></div>
<!---->


<!---滚动-->
 
    





<!---ce-->
    </div>

<!--自制的tabbar-->

  <!-- <div class="tabbar">
     <van-row>
  <van-col span="8">        <van-image :src="require('../assets/img/images/home1.png')" />  </van-col>

    <van-col span="8">    <van-image :src="require('../assets/img/images/muchan.png')" @click="muchang" /> </van-col>
  <van-col span="8"><van-image :src="require('../assets/img/images/my.png')"  @click="tomy"/> </van-col>
    </van-row>
  </div> -->

   <div style="height:80px"></div>

  <!-- <van-tabbar v-model="active_bar" @change="click_tab">
  <van-tabbar-item icon="wap-home">Home</van-tabbar-item>
  <van-tabbar-item icon="todo-list">News</van-tabbar-item>
  <van-tabbar-item icon="logistics">Product</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o">Fund</van-tabbar-item>
  <van-tabbar-item icon="manager">My</van-tabbar-item>
</van-tabbar> -->

  

    <div
      data-v-8686d654
      :class="miao==0?'van-popup van-popup--center NoticePopup2':'van-popup van-popup--center NoticePopup1'"
      style=""
      v-show="isshow==0"
    >
      <dl data-v-8686d654 class="NoticePopup">
        <dt data-v-8686d654>{{setting.gonggao_title}}</dt>
        <dd data-v-8686d654>
          <p style="white-space:pre-line">{{setting.gonggao}}</p>
          
        </dd>
        <p style="word-break: break-word;color: white;text-align: right;flex:auto">{{setting.gundong1}}</p>
        <a data-v-8686d654 class="close" v-show="miao!=0" style="font-size:18px;color:#000;flex:auto">{{miao}}S</a>
      </dl>
      <a data-v-8686d654 href="javascript:;" v-on:click="close" class="close" v-show="miao==0">
        <i
          data-v-8686d654
          class="van-icon van-icon-clear"
          style="color: black; font-size: 30px;"
        >
          <!---->
        </i>
      </a>
    
    </div>






    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <van-popup v-model="lang_show" round position="bottom">
    <van-picker
  :title="langs.tp3"
  :columns="lang_list"
  @confirm="onConfirm_lang"
  @cancel="lang_show = false"
  :show-toolbar=true
  :confirm-button-text="langs.tp2"
  :cancel-button-text="langs.tp1"
/>
</van-popup>








    <!-- 转盘包裹 -->
    <div class="zp_bg" v-show="zp_kg"> 
    <div class="rotate">
      <!-- 绘制圆点 -->
      <div
        :class="'circle circle_'+index"
        v-for="(item,index) in circleList"
        :key="index"
        :style="{background:index%2==0?colorCircleFirst:colorCircleSecond}"
      ></div>
      <!-- 转盘图片 -->
      <div
        class="dish"
        :style="{transform:rotate_deg,transition:rotate_transition}"
      >
          <div :class="'jiang'+index" class="jiangp"  v-for="(item,index) in jp" :key="index">
             <p class="jp_name one_hide">{{item.name}}</p>
             <div class="img"><img :src="item.logo"/></div>
          </div>
          <div class="jiangp jiang7"  >
             <p class="jp_name one_hide">Thank you!</p>
             <div class="img"><img src="../assets/img/2.png"/></div>
          </div>
        
      </div>
      <!-- 指针图片 -->
      <img class="pointer" src="../assets/img/wheel-btn.png" @click="start" />
    </div>
    <div class="tishi">{{langs.t10}} :{{zp_jh}} </div>
    <div class="guanbi" @click="close_zp"> <i
          data-v-8686d654
          class="van-icon van-icon-clear"
          style="color: #1989fa; font-size: 25px;"
        >
          <!---->
        </i></div>
       </div>



       <van-popup v-model="showPicker"  position="bottom" :style="{background:'white',height:'40%'}" >
  <van-picker
    show-toolbar
    :confirm-button-text="langs.tp2"
    :cancel-button-text="langs.tp1"
    :title="langs.t9"
    :columns="columns"
    :default-index="index1"
    @confirm="onConfirm1111"
    @cancel="showPicker = false"
  />
</van-popup>
<Footer :lang_list="lang_list" :lang="langs" />
  </div>
</template>

<script>
// @ is an alias to /src

var light_timer; //灯光定时器
import * as local from "@/libs/local";
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from "vant";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Grid, GridItem } from 'vant';
import * as api from "@/api";
import { ref } from "vue";
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Empty } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import * as update from '@/utils/update'
import { NavBar } from 'vant';
import { Picker } from 'vant';

import Footer from './lyout/footer.vue';
Vue.prototype.upd=update
Vue.use(Swipe);
Vue.use(NoticeBar);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(local);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(NavBar)
Vue.use(Picker);
export default {
  name: "Home",
  components: {
    //HelloWorld
    Footer,
  },
  data() {
    return {
      title: "",
      active: 0,
      active_bar:0,
      productList: [],
      howExchangeItem: null,
      popHowExchange: false,
      isshow:1,
      user:{
        "niunai":0.00,
        "jinbi":0.00
      },
      show: false,
      setting:{},
      manghe_setting:{
        count:0
      },
      miao:1,
      category:[],
      news:[],
      columns: ["Aisin Car Customer Service","USDT Customer Service"],
      columns1:["Aisin Car Customer Service","USDT Customer Service"],
      index1:0,
      help:[],
      banner:[],
      gundong:"",
      cow:{},
      activity:0,
      lang:"",
      lang_text:["English"],
      lang_show:false,
      chou_lock:false,
      text1:["Home page","الصفحة الرئيسية","Page d'accueil"],
      text2:["top-up","تعبئة رصيد","recharger"],
      text3:["withdrawal","سحب","retrait"],
      text4:["The props","أداة","Accessoires"],
      text5:["The invitation","دعوة","L'invitation"],
      text6:["seeds","البذور .","Graine"],
      text7:["orchard","بستان","Le Verger"],
      text8:["cancel","ألغى","Annuler"],
      text9:["Select the language","اختيار اللغة","Sélectionnez la langue"],
      text10:["confirm","أكّد","confirmer"],
      text11:["My"," فرد . ","Le mien"],
      text12:["My farm","مزرعتي","Ma ferme"],
      text13:["News","رسالة","Annonce"],
      text14:["Help","ساعد","Aider"],
      text15:["MORE","أكثر .","plus"],
      text16:["The latest announcement","أحدث مذيع","La dernière annonce"],
      text31:["Home","منزل .","Maison"],
      text32:["The farm","مزرعه","La ferme"],
      text33:["My"," فرد . ","Le mien"],
      zp_kg:false,
      LuckyClick: 3,
      circleList: [], //原点设置
      colorCircleFirst: "#FE4D32", //圆点颜色
      colorCircleSecond: "#fff", //圆点颜色
 
      cat: 45, //总共8个扇形区域，每个区域约45度
      isAllowClick: true, //是否能够点击
      rotate_deg: 0, //指针旋转的角度
      rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
      jp:[],
      get_id:2,
      get_msg:"",
      zp_jh:0,
      type:0,
      kefu:{},
      showPicker:false,
      lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
    };
  },
  setup() {
   const active = ref(0);
    return { active };
    
  },
  created: function() {
    this.isshow=0
  //   let token=this.$route.query.token?this.$route.query.token:"0000";
  //  if(token!='0000'){
  //   Toast(11111)
  //   local.saveInfo("jwt",token)
  //  }
  let time_r1=setInterval(()=>{
    if(this.miao<=0){
      clearInterval(time_r1)
    }else{
    this.miao=this.miao-1;
    }

  },1000)
    this.showcircleList();
   // let EXPIRESTIME = 10
   //  let setting1 = local.readInfo('setting1')
    //if(setting1 === null || setting1 === undefined || setting1 === ''){
        this.get_imdex_data()
    // }else{
    //      let date = new Date().getTime();
    //   // 如果大于就是过期了，如果小于或等于就还没过期
    //   if ((date - (setting1.startTime?setting1.startTime:0)) > EXPIRESTIME) {
    //     this.get_imdex_data()
    //   }else{
    //     this.setting=setting1.setting
    //     // if(setting1.setting.gonggao.length>10&&local.readInfo('tanchuang')==0){
    //     //         this.isshow=0
    //     //          //控制弹窗
    //     //            local.saveInfo('tanchuang',1)
    //     //       }
    //           this.user=setting1.user
    //           this.news=setting1.news
    //           this.help=setting1.help
    //           this.banner=setting1.banner
    //           if(this.setting.sfzp==1){
    //             this.zp_kg=true
    //           }
    //           this.zp_jh=this.setting.jihui
    //   }
   // }
    let _this = this;
    console.log(_this.$store);
  
  //   setTimeout(() => {
  //     if(this.manghe_setting.count>0){
  //       Dialog.confirm({
  //         title: 'magic box to inform',
  //         message: 'Dear ranchers, you unlock a new magic box game, magic box can help you get more cows, to take a look',
  //         confirmButtonText:"Go and see",
  //         cancelButtonText:"Know the"
  //      })
  //   .then(() => {
  //   //确认前往盲盒
  //      let _this = this;
  //     _this.$router.push({ name: "manghe", query: { redId: 1111 } });
  //    })
  // .catch(() => {
  //   console.log('quxiao')
  // });
  //     }
  //   }, 5000);
    //let _this = this
    //_this.$router.push({'name': 'About', query: {redId: 1111}})
      //this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
      this.lang=0
  },
  methods: {
    onClickLeft: function () {
    //  Toast(111111);
     // let baseUrl = "https://api.whatsapp.com/send?phone=";
      api.kf(
        {
          token: local.readInfo("jwt"),
        },
        (err, data) => {
          if (!err && data.code === 1) {

            //window.location.href = baseUrl + data.data.kefu.code;
            this.kefu=data.data.kefu
            this.type=0;
            this.showPicker=true
          } else {
            console.log(data);
            Toast(data.msg);
          }
        }
      );
    },
    onConfirm1111(value, index) {
           console.log(index)
           console.log(value)
                 console.log(this.type)
           console.log(this.user)
            //let baseUrl = "https://api.whatsapp.com/send?phone=";
      this.select_index=index
      if(index==0){
        if(this.type==0){
          if(this.kefu.code==null||this.kefu.code==''||this.kefu.code=='0'||this.kefu.code==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href = this.kefu.code;
           }
        }
       if(this.type==1){
          if(this.data.user.yuangong.team_nub==null||this.data.user.yuangong.team_nub==''||this.data.user.yuangong.team_nub=='0'||this.data.user.yuangong.team_nub==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href =this.data.user.yuangong.team_nub;
        }
        }
      }
      if(index==1){
        if(this.type==0){
          if(this.kefu.code1==null||this.kefu.code1==''||this.kefu.code1=='0'||this.kefu.code1==undefined){
            Toast(this.langs.tu2)
          }else{
              window.location.href = this.kefu.code1;
          }
        }
       if(this.type==1){
          if(this.data.user.yuangong.team_nub1==null||this.data.user.yuangong.team_nub1==''||this.data.user.yuangong.team_nub1=='0'||this.data.user.yuangong.team_nub1==undefined){
            Toast(this.langs.tu2)
          }else{
          window.location.href =this.data.user.yuangong.team_nub1
           }
        }
      }
      this.showPicker = false;
        },
    onClickRight:function(){
      this.lang_show=true
    },
    onConfirm_lang(value,index){
      console.log(index)
      console.log(value)
      local.saveInfo('setting_lang',index)
      local.saveInfo('car_lang',value.lang)
      this.lang_show=false
      window.location.reload();
    },
    onCancel_lang:function(){},
    howExchange: function(item) {
      // 如何兑换
      this.howExchangeItem = item;
      this.popHowExchange = true;
    },
    get_imdex_data:function(){
        api.index_data({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
            this.setting=data.data.setting
            var datas = Object.assign(data.data, { startTime: new Date().getTime() });
             local.saveInfo('setting1',datas)
              //    if(data.data.setting.gonggao.length>10&&local.readInfo('tanchuang')==0){
              //   this.isshow=0
              //         //控制弹窗
              //         local.saveInfo('tanchuang',1)
              // }
             if(data.data.setting.sfzp==1){
                this.zp_kg=true
              }
              this.user=data.data.user
              this.news=data.data.news
              this.help=data.data.help
              this.banner=data.data.banner
              this.jp=data.data.jp
              this.zp_jh=data.data.jihui

              this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
               this.columns[0]=data.lang.tp11   
               this.columns[1]=data.lang.tp12   
               this.columns1[0]=data.lang.tp11   
               this.columns1[1]=data.lang.tp12   
              // this.activity=data.data.activity
              //   this.manghe_setting=data.data.manghe_setting
              //    this.category=data.data.category
                
          } else {
              console.log(data)
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
            //this.block = false
         
          }
        
        })
    },
  //   buy:function(cow){
  //           Dialog.confirm({
  //         title: 'Buy a cow',
  //         message: 'Buy the cows need '+cow.price+' grams of milk, confirm to buy',
  //         confirmButtonText:"confirm",
  //         cancelButtonText:"cancel"
  //      })
  //   .then(() => {
  //   //确认前往盲盒
  //       api.buy({
  //       token: local.readInfo('jwt'),
  //       id:cow.id
  //       }, (err, data) => {
  //         if (!err && data.code === 1) {
  //                Toast(data.msg)
  //                this.get_imdex_data()
  //         } else {
  //             console.log(data)
  //                Toast(data.msg)
  //           //this.block = false
         
  //         }
        
  //       })
  //    })
  // .catch(() => {
  //   console.log('quxiao')
  // });
  //   },
    to_not_open:function(){
      Toast("A task is not open");
    },
    close:function(){
      this.isshow=1
    },
    close_zp:function(){
      this.zp_kg=false
    },
    toxw: function() {
      let _this = this;
      _this.$router.push({ name: "ac_list", query: { redId: 1111 } });
    },
     tofx: function() {
      let _this = this;
      _this.$router.push({ name: "promote", query: { redId: 1111 } });
    },
      to_update: function() {
      let _this = this;
      _this.$router.push({ name: "update", query: { redId: 1111 } });
    },
      to_share: function() {
      let _this = this;
      _this.$router.push({ name: "promote", query: { redId: 1111 } });
    },
    to: function(url) {
      let _this = this;
      _this.$router.push({ name: url, query: { redId: 1111 } });
    },
    tolang: function() {
      let _this = this;
      _this.$router.push({ name: "language", query: { redId: 1111 } });
    },
    
     gengduo: function() {
      let _this = this;
      _this.$router.push({ name: "ac_list", query: { redId: 1111 } });
    },
     muchang: function() {
      let _this = this;
      _this.$router.push({ name: "farm", query: { redId: 1111 } });
    },
    tudi: function() {
      let _this = this;
      _this.$router.push({ name: "land", query: { redId: 1111 } });
    },
    to_xz: function() {
     window.location.href="/web/xz/"
    },
       tomy: function() {
      let _this = this;
      _this.$router.push({ name: "my", query: { redId: 1111 } });
    },
     jiaoyi: function() {
      let _this = this;
      _this.$router.push({ name: "trading", query: { redId: 1111 } });
    }
    ,
    tochongzhi: function() {
      let _this = this;
      _this.$router.push({ name: "history", query: { redId: 1111 } });
    },
    totixian: function() {
      let _this = this;
      _this.$router.push({ name: "withdrawal", query: { redId: 1112 } });
    },
    showac(id,type) {
      let _this = this;
      _this.$router.push({ name: "ac_detail", query: { id: id,type:type } });
    },

    //绘制圆点设置
    showcircleList() {
      let circleList = [];
      for (var i = 0; i < 16; i++) {
        circleList.push(i);
      }
      this.circleList = circleList;
      this.light();
    },
 
    //闪动效果
    light: function() {
      var that = this;
      clearInterval(light_timer);
      light_timer = setInterval(function() {
        if (that.colorCircleFirst == "#FE4D32") {
          that.colorCircleFirst = "#fff";
          that.colorCircleSecond = "#FE4D32";
        } else {
          that.colorCircleFirst = "#FE4D32";
          that.colorCircleSecond = "#fff";
        }
      }, 300); //设置圆点闪烁的效果
    },
 
    start() {
      if (this.chou_lock) {
        //alert("机会已经用完了");
        return;
      }
      this.chou_lock=true;
      api.all("/qintai_public/api.user1/chou11",{
        token: local.readInfo('jwt'),
        jp:this.jp
        }, (err, data) => {
          if (!err && data.code === 1) {
            console.log(data)  
                this.get_id=data.data
                this.get_msg=data.msg
                this.rotating(data.data);
                if(this.zp_jh>0){
                this.zp_jh=this.zp_jh-1;
                this.setting.jihui=this.setting.jihui-1;
                }
          } else {
              console.log(data)
              Dialog.alert({
              message:    data.msg,
              confirmButtonText: "confirm",
               theme: 'round-button',
              }).then(() => {
              // on close
               // this.show1_1=false
              });
         
          }
        
        })
    //  this.rotating();
    },
 
    rotating(winningIndex) {
      if (!this.isAllowClick) return;
      this.isAllowClick = false;
      this.rotate_transition = "transform 3s ease-in-out";
      this.LuckyClick--;
      var rand_circle = 5; //默认多旋转5圈
      //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
      //var winningIndex = this.set(); //设置了概率的
 
      console.log(winningIndex);
      var randomDeg = 360 - winningIndex * 45; //当前下标对应的角度    45是总共8个扇形区域，每个区域约45度
 
      var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
      this.rotate_deg = "rotate(" + deg + "deg)";
 
      var that = this;
      setTimeout(function() {
        that.isAllowClick = true;
        that.rotate_transition = "";
              Dialog.alert({
              message:   that.get_msg,
              confirmButtonText: "confirm",
               theme: 'round-button',
              }).then(() => {
              // on close
               // this.show1_1=false
               that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
            
              });
            that.chou_lock=false
        // if (winningIndex == 0) {
        //   alert("恭喜您，IphoneX");
        // } else if (winningIndex == 1) {
        //   alert("恭喜您，获得10元现金");
        // } else if (winningIndex == 2) {
        //   alert("很遗憾，重在参与");
        // } else if (winningIndex == 3) {
        //   alert("恭喜您，获得30元现金");
        // } else if (winningIndex == 4) {
        //   alert("恭喜您，获得20元现金");
        // } else if (winningIndex == 5) {
        //   alert("恭喜您，获得50元现金");
        // } else if (winningIndex == 6) {
        //   alert("恭喜您，获得5元现金");
        // } else if (winningIndex == 7) {
        //   alert("恭喜您，获得100元现金");
        // }
      }, 3500);
    },
 
    //设置概率
    set() {
      var winIndex;
   
      var __rand__ = Math.random();
      if (__rand__ < 0.3) winIndex = 2;
      else if (__rand__ < 0.55) winIndex = 6;
      else if (__rand__ < 0.75) winIndex = 1;
      else if (__rand__ < 0.85) winIndex = 4;
      else if (__rand__ < 0.92) winIndex = 3;
      else if (__rand__ < 0.97) winIndex = 5;
      else if (__rand__ < 0.99) winIndex = 7;
      else if (__rand__ == 0.99) winIndex = 0;
 
      return winIndex;
    }

  }
};
</script>
<style >
.public_flex{
    display: -webkit-flex; display: -moz-box; display: -ms-flexbox;
    display: flex;-webkit-align-items: center;-moz-box-align: center;-ms-flex-align: center;
    align-items: center;-webkit-justify-content: space-between;-moz-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;
}
.one_hide{
    word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 1;
}
.two_hide{
    word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 2;
}
.three_hide{
    word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 3;
}
.fore_hide{
    word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 4;
}
.more_hide{
    word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 30;
}


.jinyong{
    pointer-events:none;
    -webkit-filter: grayscale(100%);

    /* Chrome, Safari, Opera */

    filter: grayscale(100%);
}

.header{
  width:686px;
  height:85px;
  margin:0 auto;
  background: rgba(0, 0, 0, 0);
  border-radius: 0px;
}
.header .header1{
  width: 47%;
  box-sizing: border-box;
  padding: 5px;
  padding: 0 18px;
  height: 92px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #1563b3 0%, #7869ff 60%, #1a009a 100%);
  color: #FFF;
  border-radius: 10px;
 
}
.header .header2{
  width: 47%;
  padding: 0 18px;
  height: 92px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #1563b3 0%, #7869ff 60%, #1a009a 100%);
  border-radius: 10px;
  color: #FFF;
}
.header .header1 .touxiang{
  width:75px;
  height: 75px;
  border-radius: 100%;
  border: 2px solid #249562;
  overflow: hidden;
  box-sizing: border-box;
}
.header .touxiang img{width: 100%;}
.header .name{
  width:144px;
  height: 55px;
  line-height: 55px;
  font-size: 28px;
  color: #FFF;
  text-align: left;
}
.header .header2 .usdt{
  width: 49%;
  box-sizing: border-box;
  padding-left: 44px;
  background: url('../assets/img/wd (5).png') no-repeat;
   background-size: contain;
   color: #FFF;
   font-size: 24px;
}
.header .header2 .points{
  width: 49%;
  box-sizing: border-box;
  padding-left: 44px;
  background: url('../assets/img/wd\ \(1\).png') no-repeat;
   background-size: contain;
   color: #FFF;
   font-size: 24px;
}
.header .header2 .head{
  width: 49%;
  box-sizing: border-box;
  padding-left: 44px;
  background: url('../assets/img/wd\ \(8\).png') no-repeat;
   background-size: contain;
   color: #FFF;
   font-size: 24px;
}
</style>
<style lang="css" scoped>
.content{
  width:100%;
  height: auto;
   background-size: contain;
   background-color: #fff;
   padding-top: 50px;
}
/* .home222>>>.van-tabbar{
  background: url('../assets/img1/tab_bg.png') no-repeat;
  background-size: cover;
  padding-top: 1.1rem;
  border: 0!important;
}
.home222>>>.van-tabbar::after{
  border: 0!important;
}
.home222>>>.van-tabbar .van-tabbar-item{
  color:#fff
}
.home222>>>.van-tabbar .van-tabbar-item--active{
  background: rgba(0, 0, 0, 0);
  color:#1989fa
} */
.baise>>> .van-cell__title{color: white!important;}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: auto;
}
.van-tabs__nav--card{margin:0!important}
.home222 {
  background-color: #fff;
  /* padding-bottom: 200px; */
    max-width: 750px;
  margin: 0 auto;
}
.home222 >>>.van-tab__text--ellipsis{
  -webkit-line-clamp: 2;
}
/* .content{
  width:100%;
  height: auto;
  min-height: 1624px;
  background: url('../assets/img/index.jpg') no-repeat #5ca110;
   background-size: contain;
   background-color: #5ca110;
} */

.new_title{
  width:666px;
  height:85px;
  margin: 0 auto;
  color:152331;
  font-size: 42px;
  font-weight: 600;
  text-align: left;
}

.new_news{
  width:686px; 
  height:auto;
  margin:0 auto;
}
.new_news>>>.van-cell{
  background-color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.new_news>>>.van-cell .van-cell__title span{
  text-align: left;
  max-width: 92%;
  color:#152331;
  word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 1;
}
.new_news>>>.van-cell  .van-cell__label{
  text-align: left!important;
}

.jion{
  width:100%;
  height:auto;
  margin-top: 60px;
}
.jion img{
  width:100%
}
.gonggao{
  width:686px;
  height:85px;
  margin:0 auto;
  background:#fff;
  border-radius: 20px;
  overflow: hidden;
}
.gonggao>>>.van-notice-bar{
  color:#666;
  background:#fff ;
  margin-top: 5px;
}

.lunbo{
  width:686px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
}
.lunbo>>>.van-swipe-item{
  display: flex;
}
.gongge{
    width:686px;
    height:219px;
     margin: 0 auto;
  /* background: url('../assets/img1/menu_bg1.png') no-repeat; */
   background-size: 100%;
   margin-top: 60px;
  
}
.gongge>>>.van-grid{
  padding-top: 0.2rem;
}
.gongge>>>.van-grid .van-grid-item{
  height:1.55rem
}
.gongge .biaoti{
  color:#666;
  width:40%;
  height:1.55rem;
  margin-left: 15%;
  line-height: 1.75rem;
}
.gongge>>>.van-grid-item__content{
  padding: 0px 0px;
}
.gongge img{
  width: 90%;
}
.tabs{
  background: #fff;
  width:686px;
  margin: 0 auto;
  height: 880px;
  border-radius: 16px;
  overflow: hidden;
}
.tab_nairong{
    width:100%;
    margin: 0 auto;
    min-height: 300px;
    padding: 20px;
    min-height: 500px;
    box-sizing: border-box;
}
.tabs>>>.van-tabs__content{
  min-height: 5.50rem;
}
.tab_nairong .li{
  width:650px;
  height: 180px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  background: white;
  margin-top: 25px;
  box-shadow: 3px 5px 10px 1px #dbdbdb;
}
.tab_nairong .li .title{
  width:450px;
  height: 160px;
  box-sizing: border-box;
}
.tab_nairong .li .img{
  width:140px;
  height: 160px;
}
.tab_nairong .li .title h3{
  font-size: 28px;
  color:#181816;
  line-height: 42px;
  text-align: left;
  padding: 0;
  margin:0
}
.tab_nairong .li .title p{
  color:#a6cabc;
  font-size: 24px;
  line-height: 38px;
  text-align: left;
}

.gengduo{
  width: 650px;
  height: 80px;
 line-height: 80px;
 text-align: center;
 color:white;
 background: #07c160;
 border-radius: 18px;
 margin:0 auto
}
.gengduo img{
  display: block;
  position: absolute;
  width: 97px;
  height: 52px;
  top:-20px;
  left:323px;
}




.gongge>>>.van-grid-item__content {
  background-color: rgba(0, 0, 0, 0) !important;

}
.Title {
  background-color: #151d31 !important;
  color: #fff !important;
  margin: 4px 0 !important;
  padding: 7px 25px !important;
}
.topItem {
  line-height: 1;
  color: #fff;
}
.topItem .van-cell__left-icon {
  height: 46px;
  width: 46px;

  border-radius: 100%;
  overflow: hidden;
  padding: 2px;
}

.van-cell__title,
.van-cell__value {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;

}
.van-tabs__content .van-swipe__indicators {
  display: none !important;
}
.van-cell::after {
  border: 0px !important;
}
.topItem .van-cell__title span {
  color: white;
  text-align: left!important;
  font-size: 14px;
  display: block;
  width: 100%;
}
.topItem .van-cell__title div {
  color: white;
  text-align: left!important;
  font-size: 14px;
}
.NoticePopup1{
   background: #07c160;width: 100%; text-align: center; z-index: 2002;  height: 100vh;
}
.NoticePopup2{
  animation: up5 1s linear ;
  width: 94%;

  background: transparent; 
  text-align: center; z-index: 2002;
}
.NoticePopup2 .NoticePopup{
  animation: up6 1s linear ;
  margin: 0!important;
}
@keyframes up5 {
  0% {
    width: 100%;
  }
  100% {
    width: 94%;
  }
}
@keyframes up6 {
  0% {
    height: 100vh;
  }
  100% {
    height: 75vh;
  }
}
.NoticePopup1 .NoticePopup{
  height: 85vh!important;
  margin: 0!important;
  box-sizing: border-box;
}
.NoticePopup1 .close{
  z-index: 10003;
}
.NoticePopup {
  background: #07c160;
  background-size: contain;
  height: 75vh;
  padding: 0 19px 19px 19px;
  border-radius: 29px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.NoticePopup dt {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  line-height: 62px;
}
.NoticePopup dd {
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 7px;
  color: #ccc;
  flex: auto;
  text-align: justify;
}
.NoticePopup p {
  font-size: 22px;
  font-weight: 200;
  color: #fff;
  line-height: 30px;
}
.van-nav-bar__text {
  margin: 0;
  padding: 0;
  color: #bbb !important;
}
.van-nav-bar__text {
  margin: 0;
  padding: 0;
  color: #bbb !important;
}
.MemberList{
  width:720px;background: rgba(0, 0, 0, 0.3);margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 50px;
  display: none;
}








.times {
  text-align: center;
  line-height: 0.8rem;
  background: #fff;
}
.rotate {
  width: 6.1rem;
  height: 6.1rem;
  background: #1989fa;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -3.05rem;
   /* transform: translate(-50%, -50%); */
  transform: scale(1.55);
  position: fixed;
}
 
.rotate .dish {
  width: 5.5rem;
  height: 5.5rem;
}
 
.pointer {
  width: 1.6rem;
  height: 2.03rem;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
/* 圆点 */
.rotate .circle {
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 0.16rem;
  width: 0.16rem;
  background: black;
}
 
.rotate .circle_0 {
  top: 0.08rem;
  left: 2.92rem;
}
 
.rotate .circle_1 {
  top: 0.28rem;
  left: 4.05rem;
}
 
.rotate .circle_2 {
  top: 0.86rem;
  left: 4.95rem;
}
 
.rotate .circle_3 {
  top: 1.85rem;
  left: 5.65rem;
}
 
.rotate .circle_4 {
  top: 2.85rem;
  right: 0.1rem;
}
 
.rotate .circle_5 {
  bottom: 1.89rem;
  right: 0.29rem;
}
 
.rotate .circle_6 {
  bottom: 0.96rem;
  right: 0.88rem;
}
 
.rotate .circle_7 {
  bottom: 0.34rem;
  right: 1.76rem;
}
 
.rotate .circle_8 {
  bottom: 0.06rem;
  right: 3.06rem;
}
 
.rotate .circle_9 {
  bottom: 0.28rem;
  left: 1.9rem;
}
 
.rotate .circle_10 {
  bottom: 0.96rem;
  left: 0.88rem;
}
 
.rotate .circle_11 {
  bottom: 1.82rem;
  left: 0.28rem;
}
 
.rotate .circle_12 {
  top: 2.9rem;
  left: 0.1rem;
}
 
.rotate .circle_13 {
  top: 1.9rem;
  left: 0.28rem;
}
 
.rotate .circle_14 {
  top: 1rem;
  left: 0.86rem;
}
 
.rotate .circle_15 {
  top: 0.32rem;
  left: 1.76rem;
}
.dish{
  background: url("../assets/img/zp.png") no-repeat;
  background-size: contain;
  position: relative;
}



.jiangp{
  width:1.8rem;
  height: 1.8rem;
  position: absolute;
  overflow: hidden;
  top:0;left:0
}
.jiangp .img{
  width: 50px;
  height: 70px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 10px;
}
.jiangp p{
  margin: 0!important;
  text-align: center;
  color:Red;
  font-size:16px!important;
  word-break: break-all;  display: -webkit-box;text-overflow: ellipsis;
    overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 2;
}
.jiangp .img img{
  width:100%
}
.jiang0{
  top:0.2rem;
  left:1.8rem;
  transform: rotate(-1deg);
}
.jiang1{
  top:0.6rem;
  left:3.0rem;
  transform: rotate(45deg);
}
.jiang2{
  top:3rem;
  left:3.1rem;
  transform: rotate(135deg);
}
.jiang3{
  top:3.5rem;
  left:1.9rem;
  transform: rotate(180deg);
}
.jiang4{
  top:3.1rem;
  left:0.6rem;
  transform: rotate(225deg);
}
.jiang5{
  top:1.8rem;
  left:0.1rem;
  transform: rotate(270deg);
}
.jiang6{
  top:0.7rem;
  left:0.6rem;
  transform: rotate(315deg);
}
.jiang7{
  top:1.8rem;
  left:3.6rem;
  transform: rotate(90deg);
}
.zp_bg{
  width:100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
}
.zp_bg .guanbi{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 82%;
  text-align: center;
  font-size: 42px;
  z-index: 9999;
}
.zp_bg .guanbi i{
  font-size: 52px!important;
  color:red
}
.zp_bg .tishi{
  line-height:40px;text-align:center;color:#fff;
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0%;
  margin-left: 0x;
  top: 79%;
  font-size: 28px;
}
</style>
