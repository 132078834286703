// 内置浏览器类型
export const INNER_WECHAT = 1 // 微信内置浏览器
export const INNER_QQ = 2 // 手Q浏览器
export const INNER_NO = 0 // 非内置浏览器

// 登录状态
export const NOT_LOGIN = -1 // 未登录
export const LOGIN_TYPE_MOBILE = 1 // 手机登录
export const LOGIN_TYPE_WECHAT = 2 // 微信登录

// code
export const CODE_SYS_ERR = 0 // 系统正在维护
export const CODE_NOT_AUTH = 1 // 获取不到微信授权地址
export const CODE_NOT_WX = 2 // 不是微信环境
export const CODE_AUTH_ERR = 3 // 授权失败

// 功能状态
export const MODULE_STATE_OPEN = '1' // 功能模块开启
export const MODULE_STATE_PAUSE = '2' // 功能模块暂时关闭
export const MODULE_STATE_CLOSE = '3' // 功能模块关闭
