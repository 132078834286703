// 用户登录类型
export const getLoginType = state => {
  return state.loginType
}

// 用户uid
export const getUid = state => {
  return state.userInfo.id
}

// 用户token
export const getToken = state => {
  return state.jwt
}

// 用户role
export const getRole = state => {
  return state.userInfo.role
}

// 用户头像
export const getAvatar = state => {
  return state.userInfo.avatar_url
}

// 全部userinfo
export const getUserInfo = state => {
  return state.userInfo
}
