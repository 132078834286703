<template>


      <div class="header-mobile" v-if="not.indexOf(routeName)<0">
        <div style="height: 1rem;"></div>
        <van-tabbar route v-if="not.indexOf(routeName)<0">
  <van-tabbar-item icon="wap-home" to="/">{{lang.tp4}}</van-tabbar-item>
  <van-tabbar-item icon="todo-list" to="/ac_list">{{lang.tp7}}</van-tabbar-item>
  <van-tabbar-item icon="logistics" to="/land">{{lang.tp8}}</van-tabbar-item>
  <van-tabbar-item icon="chart-trending-o" to="/fund">{{lang.tp5}}</van-tabbar-item>
  <van-tabbar-item icon="manager" to="/my">{{lang.tp6}}</van-tabbar-item>
</van-tabbar>
      </div>
      
</template>
<style scoped>
::v-deep .van-tabbar-item .van-tabbar-item__text{
  font-weight: 700;
}
</style>
<script>  

export default {  
  props: {  
    lang_list: {},
    lang: {},
  },
  data() {  
    return {  
      search_value: "",  
   
      not:'Login,login,Registeremail,registeremail,language,Language,line,Line',
    };  
  }, 
  computed: {
    routeName: function () {
    //  console.log(this.$route.name)
     
      return this.$route.name
    },
  
   }, 

}  
</script>