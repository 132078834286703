// 保存指定信息
export const saveInfo = function (str, data) {
  localStorage.setItem(str, JSON.stringify(data))
}

// 清除指定信息
export const deleteInfo = function (str) {
  localStorage.removeItem(str)
}

// 读取指定信息
export const readInfo = function (str) {
  return JSON.parse(localStorage.getItem(str))
}

export const isAndroid = function (ua) {
  return (ua.os.name.toLowerCase() === 'android' || ua.browser.name.toLowerCase() === 'android browser')
}

export const isIOS = function (ua) {
  return (ua.os.name.toLowerCase() === 'ios' || ua.browser.name.toLowerCase() === 'safari')
}

export const isWechat = function (ua) {
  return (ua.browser.name.toLowerCase() === 'wechat') || /(micromessenger)/i.test(ua.ua)
}

export const isQQ = function (ua) {
  // 安卓QQ浏览器 Mozilla/5.0 (Linux;U;Android 7.1.1;zh-cn;MI 6 Build/NMF26X)AppleWebKit/537.36(KHTML, like Gecko)Version/4.0 Chrome/37.0.0.0 MQQBrowser/7.6 Mobile Safari/ 537.36
  return /[^M]QQ/.test(ua.ua)
}

export const is2345 = function (ua) {
  return /(mb2345)/i.test(ua.ua)
}

export const showWechatLogin = function (ua) {
  // UC浏览器 QQ浏览器 safari需要显示
  return ua.browser.name === 'Mobile Safari' || // ios safari
    /(uc)/i.test(ua.ua) || // uc 浏览器
    // /(qq)/i.test(ua.ua) || // 手Q内置浏览器
    /(mqqbrowser)/i.test(ua.ua) || // QQ浏览器
    /(ucbrowser)/i.test(ua.ua) // uc 浏览器
}

if (!window.localStorage) {
  /* eslint-disable no-extra-parens */
  Object.defineProperty(window, 'localStorage', new(function () {
    /* eslint-disable one-var */
    var aKeys = [], oStorage = {}
    Object.defineProperty(oStorage, 'getItem', {
      value: function (sKey) { return sKey ? this[sKey] : null },
      writable: false,
      configurable: false,
      enumerable: false
    })
    Object.defineProperty(oStorage, 'key', {
      value: function (nKeyId) { return aKeys[nKeyId] },
      writable: false,
      configurable: false,
      enumerable: false
    })
    Object.defineProperty(oStorage, 'setItem', {
      value: function (sKey, sValue) {
        if (!sKey) { return }
        document.cookie = escape(sKey) + '=' + escape(sValue) + '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/'
      },
      writable: false,
      configurable: false,
      enumerable: false
    })
    Object.defineProperty(oStorage, 'length', {
      get: function () { return aKeys.length },
      configurable: false,
      enumerable: false
    })
    Object.defineProperty(oStorage, 'removeItem', {
      value: function (sKey) {
        if (!sKey) { return }
        document.cookie = escape(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
      },
      writable: false,
      configurable: false,
      enumerable: false
    })
    this.get = function () {
      var iThisIndx
      for (var sKey in oStorage) {
        iThisIndx = aKeys.indexOf(sKey)
        if (iThisIndx === -1) {
          oStorage.setItem(sKey, oStorage[sKey])
        } else {
          aKeys.splice(iThisIndx, 1)
        }
        delete oStorage[sKey]
      }
      for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) { oStorage.removeItem(aKeys[0]) }
      for (var aCouple, iKey, nIdx = 0, aCouples = document.cookie.split(/\s*;\s*/); nIdx < aCouples.length; nIdx ++) {
        aCouple = aCouples[nIdx].split(/\s*=\s*/)
        if (aCouple.length > 1) {
          oStorage[iKey = unescape(aCouple[0])] = unescape(aCouple[1])
          aKeys.push(iKey)
        }
      }
      return oStorage
    }
    this.configurable = false
    this.enumerable = true
  })())
}

export const localStorage = window.localStorage
