import * as types from './mutation-type'

// 保存登录类型和jwt
export const login = ({ commit }, obj) => {
  commit(types.LOGIN, obj)
}

// 注销
export const logout = ({ commit }) => {
  commit(types.LOGOUT)
}

// 保存用户信息
export const savaUserInfo = ({ commit }, obj) => {
  commit(types.USERINFO, obj)
}

// 改变asidebtn位置
export const changeAside = ({ commit }, n) => {
  commit(types.ASIDEBTN, n)
}

export const showAside = ({ commit }, b) => {
  commit(types.ASIDEBTNSHOW, b)
}

export const showNav = ({ commit }, b) => {
  commit(types.NAVSHOW, b)
}

export const savaConfig = ({ commit }, obj) => {
  commit(types.CONFIG, obj)
}
